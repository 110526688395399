body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background-color: #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* azul = #002D72 */
/* cyan = #009FE3 */
.h10 {
  height: 30px;
}
.dnd {
  background-color: #bfcecd;
  margin-bottom: 20px;
  box-shadow: 8px 8px 14px -8px rgba(0, 0, 0, 0.53);
  border-radius: 5px;
  padding: 5px 0 0 10px;
}
.dnd.hover {
  background-color: #2d9891;
}
textarea {
  box-shadow: 8px 8px 14px -8px rgba(0, 0, 0, 0.53);
}
.scrollbar {
  position: relative;
  /* height: 200px; */
  overflow: auto;
}
.display-block {
  display: block;
}
.logo {
  width: 30vw;
}
.logo-revisa {
  height: 80px;
  margin-right: -15px;
}
.logo-psu {
  height: 80px;
  margin-left: -15px;
}
.input-rut {
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 0;
}
.form-control:focus {
  box-shadow: none;
}
button:focus {
  outline: 0px;
}
.btn-resultados {
  padding: 10px 25px;
  background-color: #089cee;
  /* border-color: #05aff6; */
  color: white;
  border: 0;
  border-radius: 20px;
  font-weight: bold;
  transition: 0.3s;
}
.btn-resultados:hover {
  background-color: #088cd3;
  /* border-color: #002d72; */
}
.btn-resultados.disabled {
  background-color: #2c6f92;
  /* border-color: #77b0c8; */
  opacity: 0.7;
  cursor: default;
  box-shadow: none;
}
@keyframes fadein {
  0% {
    opacity: 0;
    margin-top: -7px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.btn-name {
  border-radius: 0px;
  box-shadow: 8px 8px 14px -8px rgba(0, 0, 0, 0.53);
  background-color: #002d72;
  border-color: #002d72;
  padding: 10px 0;
  animation: fadein 0.5s;
}
.btn-name:hover {
  background-color: #009fe3;
  border-color: #009fe3;
}
.progress {
  background-color: #8a96a2;
  height: 25px;
}
.progress-bar {
  height: 25px;
}

/*btn-modal close*/
.close-modal {
  border-radius: 0;
  background-color: #3c615e;
  border-color: #3c615e;
  font-weight: 700;
}
.close-modal:hover {
  background-color: #618685;
  border-color: #618685;
}
/*btn guardar form modal*/
.guardar-form {
  border-radius: 0;
  background-color: #44a69c;
  border-color: #44a69c;
  font-weight: 700;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.guardar-form:hover {
  background-color: #4db6ac;
  border-color: #4db6ac;
}
.close-modal:focus,
.guardar-form:focus {
  outline: none;
  box-shadow: none;
}

/* btn carga */
a.more-bt:hover {
  color: white;
  text-decoration: none;
}
.bt {
  width: 100px;
  cursor: pointer;
  position: relative;
  /* font-family: "Roboto"; */
  text-transform: uppercase;
  font-weight: 700;
  color: #618685;
  letter-spacing: 0.5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.more-bt {
  border-right: 2px solid #618685;
  border-bottom: 2px solid #618685;
  /* padding: 17px 29px 15px 31px; */
  padding: 17px;
  border-color: #618685;
}

.more-bt p {
  font-size: 14px;
  margin-bottom: 0;
}

#wrapper.smooth section.smoothy.show {
  visibility: visible;
}

.more-bt:before {
  left: 0;
  bottom: 0;
  height: -webkit-calc(100% - 17px);
  height: calc(100% - 17px);
  width: 2px;
}

.more-bt:after,
.more-bt:before {
  content: ' ';
  display: block;
  background: #618685;
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 10;
}

.more-bt:after {
  top: 0;
  right: 0;
  width: -webkit-calc(100% - 17px);
  width: calc(100% - 17px);
  height: 2px;
}

.more-bt:after,
.more-bt:before {
  content: ' ';
  display: block;
  background: #618685;
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 10;
}

::selection {
  background: #618685;
  color: #ffffff;
  text-shadow: none;
}

.more-bt:before {
  left: 0;
  bottom: 0;
  height: -webkit-calc(100% - 17px);
  height: calc(100% - 17px);
  width: 2px;
}

.more-bt:after,
.more-bt:before {
  content: ' ';
  display: block;
  background: #618685;
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 10;
}

.more-bt .fl,
.more-bt .sfl {
  position: absolute;
  right: 0;
  height: 100%;
  width: 0;
  z-index: 2;
  background: #618685;
  top: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.more-bt .fl,
.more-bt .sfl {
  position: absolute;
  right: 0;
  height: 100%;
  width: 0;
  z-index: 2;
  background: #618685;
  top: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.more-bt .sfl {
  z-index: 1;
  background: #3c615e;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.more-bt .cross {
  position: absolute;
  z-index: 15;
  width: 18px;
  height: 18px;
  top: -webkit-calc(50% - 8px);
  top: calc(50% - 8px);
  left: -webkit-calc(50% - 8px);
  left: calc(50% - 8px);
}

#wrapper.smooth section.smoothy {
  visibility: hidden;
}

.more-bt .cross:before {
  width: 100%;
  height: 2px;
  top: 8px;
  left: 0px;
  -webkit-transform: translateX(50px) scaleX(0);
  -ms-transform: translateX(50px) scaleX(0);
  transform: translateX(50px) scaleX(0);
}

.more-bt .cross:before,
.more-bt .cross:after {
  content: ' ';
  background: #fff;
  display: block;
  position: absolute;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.more-bt .cross:after {
  width: 2px;
  height: 100%;
  left: 8px;
  top: 0;
  -webkit-transform: translateY(20px) scaleY(0);
  -ms-transform: translateY(20px) scaleY(0);
  transform: translateY(20px) scaleY(0);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.more-bt .cross:before,
.more-bt .cross:after {
  content: ' ';
  background: #fff;
  display: block;
  position: absolute;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.more-bt i {
  position: absolute;
  display: block;
  top: 1px;
  left: 1px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 10;
}

.more-bt i:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.more-bt i:after,
.more-bt i:before {
  content: ' ';
  display: block;
  width: 2px;
  height: 20px;
  background: #618685;
  position: absolute;
  margin: -10px -1px;
  left: 50%;
  top: 50%;
  transition: 0.3s;
}

.more-bt:hover i:after {
  content: ' ';
  display: block;
  width: 2px;
  height: 20px;
  background: #618685;
  position: absolute;
  margin: 0px -1px;
  left: 50%;
  top: 50%;
}

.more-bt:hover i:before {
  content: ' ';
  display: block;
  width: 2px;
  height: 20px;
  background: #618685;
  position: absolute;
  margin: -10px 0px -10px 8px;
  left: 50%;
  top: 50%;
}

.more-bt p {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  z-index: 1;
}

.more-bt:hover:before,
.more-bt:before {
  height: 100%;
}

.more-bt:before {
  left: 0;
  bottom: 0;
  height: -webkit-calc(100% - 17px);
  height: calc(100% - 17px);
  width: 2px;
}

.more-bt:hover .fl,
.more-bt .fl {
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.more-bt:hover .fl,
.more-bt:hover .sfl,
.more-bt .fl,
.more-bt .sfl {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  width: 100%;
}

.more-bt .fl,
.more-bt .sfl {
  position: absolute;
  right: 0;
  height: 100%;
  width: 0;
  z-index: 2;
  background: #618685;
  top: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.more-bt:hover .sfl,
.more-bt .sfl {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.more-bt:hover .fl,
.more-bt:hover .sfl,
.more-bt.hvd .fl,
.more-bt.hvd .sfl {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  width: 100%;
}

.more-bt .sfl {
  z-index: 1;
  background: #3c615e;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.more-bt .cross {
  position: absolute;
  z-index: 15;
  width: 18px;
  height: 18px;
  top: -webkit-calc(50% - 8px);
  top: calc(50% - 8px);
  left: -webkit-calc(50% - 8px);
  left: calc(50% - 8px);
}

.more-bt:hover .cross:before,
.more-bt .cross:before {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.more-bt:hover .cross:after,
.more-bt:hover .cross:before,
.more-bt .cross:after,
.more-bt .cross:before {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.more-bt .cross:after {
  width: 2px;
  height: 100%;
  left: 8px;
  top: 0;
  -webkit-transform: translateY(20px) scaleY(0);
  -ms-transform: translateY(20px) scaleY(0);
  transform: translateY(20px) scaleY(0);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.more-bt .cross:before,
.more-bt .cross:after {
  content: ' ';
  background: #fff;
  display: block;
  position: absolute;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.more-bt:hover .cross:after,
.more-bt:hover .cross:before,
.more-bt.hvd .cross:after,
.more-bt.hvd .cross:before {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.more-bt:hover .cross:after,
.more-bt.hvd .cross:after {
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

/* btn edit */
.hover-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 300px; */
  height: 50px;
  background-color: #4db6ac;
  /* border-radius: 99px; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}
.hover-btn:before,
.hover-btn:after {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  transition: 0.25s linear;
  z-index: 1;
}
.hover-btn:before {
  content: '';
  left: 0;
  justify-content: flex-end;
  background-color: #4db6ac;
}
.hover-btn:after {
  content: '';
  right: 0;
  justify-content: flex-start;
  background-color: #44a69c;
}
.hover-btn:hover {
  background-color: #618685;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.hover-btn:hover span {
  opacity: 0;
  z-index: -3;
}
.hover-btn:hover:before {
  opacity: 0.5;
  transform: translateY(-100%);
}
.hover-btn:hover:after {
  opacity: 0.5;
  transform: translateY(100%);
}
.hover-btn span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
}
.hover-btn .link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 25%; */
  width: 40%;
  height: 100%;
  text-decoration: none;
  transition: 0.25s;
}
.hover-btn .link i {
  text-shadow: 1px 1px rgba(70, 98, 127, 0.7);
  transform: scale(1);
}
.hover-btn .link:hover {
  background-color: rgba(245, 245, 245, 0.1);
}
.hover-btn .link:hover i {
  animation: bounce 0.4s linear;
}

@keyframes bounce {
  40% {
    transform: scale(1.4);
  }
  60% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/* fin btns */

#resultados .table td {
  width: 150px;
}

@media (min-width: 992px) {
  .logo {
    width: 200px;
  }
  .logo-revisa {
    max-width: 350px;
    height: auto;
  }
  .logo-psu {
    max-width: 350px;
    height: auto;
  }
}

.main {
  background-color: #273a58;
}

#home {
  min-height: 100vh;
  background: url('./assets/ensayo-nacional-paes-uss-3.webp') no-repeat
    rgba(25, 34, 46, 0.47) 0% 0% / 100%;
  background-blend-mode: multiply;
  /* background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover; */
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }
  #home {
    background: #0c3451 !important;
    /* background: url('./assets/resultados-ensayo-presencial-fondo-webp.webp')
      no-repeat rgba(25, 34, 46, 0.47) 30% 40% / auto !important; */
  }
}

@media only screen and (min-width: 768px) {
  #bottom-box {
    position: absolute;
    bottom: 30px;
    width: 100%;
    margin-top: 0 !important;
    padding-top: 0 !important;
    color: #fff;
  }
}

/* COLORS */
.bg-blue-dark {
  background-color: rgb(11, 20, 31);
}
.bg-gray-light {
  background-color: rgb(246, 247, 247);
}
.bg-light-blue {
  background-color: rgb(228, 232, 252);
}
.bg-blue-1 {
  background-color: rgb(0, 33, 81);
}
.bg-blue-2 {
  background-color: rgb(15, 72, 122);
}
.bg-blue-3 {
  background-color: rgb(35, 79, 158);
}
.bg-blue-4 {
  background-color: rgb(38, 106, 166);
}
.bg-footer {
  background-color: rgb(40, 51, 65);
}

/* BUTTONS */
.btn-main {
  background: #c5cdf9;
  color: #001894;
  font-size: 0.9907rem;
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  /* height: 3rem; */
  line-height: 2rem;
  width: 12.8125rem;
  border-radius: 41px;
}
.btn-main:hover {
  background: #1565c0;
  color: #ffffff;
}

.btn-form {
  background: #001894;
  color: #ffffff;
  font-size: 0.8669rem;
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  height: 2.5rem;
  width: 10.9375rem;
  border-radius: 41px;
}
.btn-form:hover {
  background: #1565c0;
  color: #ffffff;
}
